import httpClient from '@/api/http-client';
import { FileObject } from '@/api/endpoints/files';

interface TaxonomyRelationship {
  id: number;
  title: string;
}

export interface ProfileObject {
  major?: TaxonomyRelationship[];
  minor?: TaxonomyRelationship[];
  standing?: TaxonomyRelationship;
  race?: TaxonomyRelationship;
  gender?: TaxonomyRelationship;
  purpose: string[];
}

export interface UserObject {
  id: number;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  email_verified_at?: string;
  password?: string;
  password_confirmation?: string;
  is_admin?: boolean;
  created_at: string;
  updated_at: string;
  last_login?: string;
  login_count?: number;
  profile?: ProfileObject;
  avatar?: string;
  photo?: FileObject;
  research_consent: boolean;
  experiences_count?: number;
  students_count?: number;
  pending_students_count?: number;
  guides_count?: number;
  pending_guides_count?: number;
  status?: number;
  college_student?: boolean;
}

export async function me() {
  return httpClient.get<UserObject>('auth/me');
}
