import { types } from 'mobx-state-tree';

export const File = types.model('File', {
  id: types.identifierNumber,
  user_id: types.maybe(types.number),
  asset_id: types.maybe(types.number),
  name: types.string,
  mime: types.string,
  uri: types.string,
  size: types.maybe(types.number),
  alt: types.string,
  title: types.string,
  created_at: types.string,
  updated_at: types.maybe(types.string),
  image: types.string,
  thumbnail: types.string,
});
