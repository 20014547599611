import httpClient from '@/api/http-client';
import { FileObject } from '@/api/endpoints/files';
import { UserObject } from './me';

export interface UpdateProfilePayload
  extends Omit<
    UserObject,
    'id' | 'email_verified_at' | 'created_at' | 'updated_at' | 'photo' | 'is_admin'
  > {
  photo?: Pick<FileObject, 'id'>;
}

export interface UpdateRegistrationPayload
  extends Omit<
    UserObject,
    | 'id'
    | 'email_verified_at'
    | 'created_at'
    | 'updated_at'
    | 'last_login'
    | 'login_count'
    | 'photo'
    | 'is_admin'
    | 'name'
    | 'email'
  > {
  photo?: Pick<FileObject, 'id'>;
}

export async function updateProfile(data: UserObject) {
  const postData: UpdateProfilePayload = {
    ...data,
    password: data.password && data.password.length ? data.password : undefined,
    password_confirmation:
      data.password_confirmation && data.password_confirmation.length
        ? data.password_confirmation
        : undefined,
  };
  return httpClient.post<UserObject>('auth/me', postData);
}

export async function updateRegistration(data: UserObject) {
  const postData: UpdateRegistrationPayload = {
    ...data,
  };
  return httpClient.post<UserObject>('auth/me', postData);
}
