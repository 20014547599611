import httpClient from '@/api/http-client';
import { Paginated } from '@/api/types';

export interface FileObject {
  id: number;
  user_id?: number;
  asset_id?: number;
  asset_type?: string;
  name: string;
  mime: string;
  uri: string;
  size?: number;
  alt: string;
  title: string;
  created_at: string;
  updated_at?: string;
  image: string;
  thumbnail: string;
}
export async function upload(image: string | Blob) {
  const formData = new FormData();
  formData.append('image', image);

  return httpClient.post<FileObject>('upload/image', formData);
}

export async function list() {
  return httpClient.get<Paginated<FileObject[]>>('files');
}
