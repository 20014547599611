import { types, cast } from 'mobx-state-tree';
import { AxiosResponse } from 'axios';

import api from '@/api';
import {
  TaxonomyAPIEndpoint,
  TaxonomyJSON,
  TaxonomyListResponse,
} from '@/api/endpoints/taxonomies';

import { Taxonomy } from '@/store/models/taxonomy';
import FetchState from '@/store/models/fetch-state';

export const TaxonomyStoreFactory = <M extends typeof Taxonomy>(
  model: M,
  apiEndpoint: TaxonomyAPIEndpoint
) =>
  types
    .model(model.name + 'Store', {
      list: types.optional(types.array(types.late(() => model)), []),
      listFetchState: types.optional(FetchState, {}),
    })
    .actions((self) => ({
      setList: (list: TaxonomyJSON[]) => {
        self.list.replace(list.map((item) => cast(item)));
      },
    }))
    .actions((self) => {
      let promise: Promise<AxiosResponse<TaxonomyListResponse>> | undefined;
      return {
        loadList: async () => {
          if (self.listFetchState.isResolved) {
            return Promise.resolve();
          }

          if (promise) {
            await promise;
            return Promise.resolve();
          }

          promise = self.listFetchState.handlePromise(() => api.taxonomies.list(apiEndpoint));
          const response = await promise;
          self.setList(response.data);
          self.listFetchState.resolve();
        },
      };
    });
