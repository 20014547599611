import httpClient from '@/api/http-client';
import { Paginated } from '@/api/types';

export interface User {
  id: number;
  name: string;
}

export interface Activity {
  id: number;
  type: 'comment' | 'reaction' | 'experience' | 'invitation';
  snippet: string;
  created_at: string;
  updated_at: string;
  title: string;
  author: User;
  asset: {
    id: number;
    experience_id?: number;
    type: string;
    body: string;
    owner: User;
    images: any[];
    reaction: any[];
  };
}

export async function getActivities() {
  return httpClient.get<Paginated<Activity[]>>('activities');
}
