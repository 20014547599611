import { AxiosError, AxiosResponse } from 'axios';

export interface APIErrorResponse {
  message: string;
  errors: Record<string, string[]>;
}
export interface APIError<T> extends AxiosError<T> {
  response: AxiosResponse<T>;
}

export function isAPIError<R = any>(error: any): error is AxiosError<R> {
  if (error && error.isAxiosError === true) {
    return true;
  }

  return false;
}

export function isAPIErrorMessage<R extends { message: string }>(error: any): error is APIError<R> {
  if (
    error &&
    error.isAxiosError === true &&
    error.response &&
    error.response.data &&
    error.response.data.message
  ) {
    return true;
  }

  return false;
}

export function isAPIErrorsObject<R extends APIErrorResponse>(error: any): error is APIError<R> {
  if (
    error &&
    error.isAxiosError === true &&
    error.response &&
    error.response.data &&
    error.response.data.message &&
    error.response.data.errors
  ) {
    return true;
  }

  return false;
}
