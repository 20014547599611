import { types, Instance } from 'mobx-state-tree';

import { InviteType, InviteStatus } from '@/api/endpoints/invites';

import UserRelationship from './user-relationship';

export const InviteModel = types.model({
  id: types.identifierNumber,
  owner_id: types.number,
  type: types.enumeration<InviteType>([InviteType.Student, InviteType.Guide]),
  status: types.enumeration<InviteStatus>([
    InviteStatus.Pending,
    InviteStatus.Accepted,
    InviteStatus.Declined,
    InviteStatus.Canceled,
    InviteStatus.Archived,
  ]),
  created_at: types.string,
  updated_at: types.string,
  owner: UserRelationship,
  student: UserRelationship,
  guide: UserRelationship,
  experience_count: types.optional(types.number, 0),
});

export type IInviteModel = Instance<typeof InviteModel>;

export default InviteModel;
