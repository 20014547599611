import { types, getSnapshot, castToSnapshot } from 'mobx-state-tree';

import api from '@/api';
import { RegisterPayload } from '@/api/endpoints/auth/register';

import taxonomies from '@/store/stores/taxonomies';
import quiz from '@/store/stores/quiz';
import activites from '@/store/stores/activities';
import {
  RequestPasswordResetPayload,
  UserObject,
  ValidateTokenPayload,
  ResetPasswordPayload,
} from '@/api/endpoints/auth';

import { FetchState } from '@/store/models/fetch-state';
import { IFetchStatus } from '@/store/types/fetch-status';
import { User } from '@/store/stores/user';
import truenorth from '@/store/stores/truenorth';
import guides from '@/store/stores/invites/guides';
import students from '@/store/stores/invites/students';
import experiences from '@/store/stores/experiences';
import users from '@/store/stores/users';
import { getInviteObject, InviteTokenObject, removeInviteObject } from '@/api/endpoints/auth/utils';

export enum LoginStatus {
  LoggedOut = 'logged-out',
  LoggingIn = 'logging-in',
  LoggedIn = 'logged-in',
  LoggingOut = 'logging-out',
}

export enum TokenStatus {
  PendingVerify = 'logged-out',
  Verified = 'logging-in',
  Failed = 'logged-in',
  LoggingOut = 'logging-out',
}

export const RootStore = types
  .model('RootStore', {
    loginStatus: types.optional(
      types.enumeration<LoginStatus>('loginStatus', [
        LoginStatus.LoggedOut,
        LoginStatus.LoggingIn,
        LoginStatus.LoggedIn,
        LoginStatus.LoggingOut,
      ]),
      LoginStatus.LoggingIn
    ),
    requestPasswordResetStatus: false,
    registerStatus: false,
    registerSaveState: types.optional(FetchState, {}),
    loginFetchState: types.optional(FetchState, {}),
    resetPasswordSaveState: types.optional(FetchState, { status: IFetchStatus.resolved }),
    user: types.maybe(User),
    editableUser: types.maybe(User),
    userSaveState: types.optional(FetchState, { status: IFetchStatus.resolved }),
    /* substores */
    taxonomies: types.optional(taxonomies, {}),
    quiz: types.optional(quiz, {}),
    activites: types.optional(activites, {}),
    truenorth: types.optional(truenorth, {}),
    users: types.optional(users, {}),
    guides: types.optional(guides, {}),
    students: types.optional(students, {}),
    experiences: types.optional(experiences, {}),
  })
  .views((self) => ({
    get isLoggedIn() {
      return self.loginStatus === LoginStatus.LoggedIn;
    },
    get isLoggedOut() {
      return self.loginStatus === LoginStatus.LoggedOut;
    },
    get isLoggingIn() {
      return self.loginStatus === LoginStatus.LoggingIn;
    },
    get isLoggingOut() {
      return self.loginStatus === LoginStatus.LoggingOut;
    },
  }))
  .actions((self) => ({
    setLoginStatus(status: LoginStatus) {
      self.loginStatus = status;
    },
    setRequestPasswordResetStatus(status: boolean) {
      self.requestPasswordResetStatus = status;
    },
    setRegisterStatus(status: boolean) {
      self.registerStatus = status;
    },
    setUser(user?: UserObject, { updateEditable = true } = {}) {
      if (user) {
        const snapshot = castToSnapshot(user);
        self.user = snapshot;
        if (updateEditable) {
          self.editableUser = snapshot;
        }
      } else {
        self.user = undefined;
        if (updateEditable) {
          self.editableUser = undefined;
        }
      }
    },
  }))
  .actions((self) => {
    return {
      takeoverInvitation: async () => {
        try {
          const data: InviteTokenObject | undefined = getInviteObject();
          if (data) {
            const user = await self.loginFetchState.handlePromise(() =>
              api.auth.takeoverInvitation(data)
            );
            self.setUser(user);
            removeInviteObject();
          }
        } catch (error) {
          // TODO: What do we do if this fails?
        }
      },
    };
  })
  .actions((self) => {
    return {
      init: async () => {
        self.setLoginStatus(LoginStatus.LoggingIn);
        try {
          const user = await self.loginFetchState.handlePromise(() => api.auth.login());
          if (!user) {
            self.loginFetchState.resolve();
            self.setLoginStatus(LoginStatus.LoggedOut);
            return;
          }

          self.setUser(user);
          // Takeover invitations if possible
          await self.takeoverInvitation();

          await self.quiz.loadLatest();
          await self.truenorth.loadLatest();

          self.loginFetchState.resolve();
          self.setLoginStatus(LoginStatus.LoggedIn);
        } catch (error) {
          self.loginFetchState.reject(error);
          self.setLoginStatus(LoginStatus.LoggedOut);
        }
      },
      login: async (data: { email: string; password: string }) => {
        self.setLoginStatus(LoginStatus.LoggingIn);
        try {
          const user = await self.loginFetchState.handlePromise(() => api.auth.login(data));
          if (!user) {
            self.loginFetchState.resolve();
            self.setLoginStatus(LoginStatus.LoggedOut);
            return;
          }

          self.setUser(user);

          // Takeover invitations if possible
          await self.takeoverInvitation();

          await self.quiz.loadLatest();
          await self.truenorth.loadLatest();

          self.loginFetchState.resolve();
          self.setLoginStatus(LoginStatus.LoggedIn);
        } catch (error) {
          self.loginFetchState.reject(error);
          self.setLoginStatus(LoginStatus.LoggedOut);
        }
      },
      logout: async () => {
        self.setLoginStatus(LoginStatus.LoggingOut);
        try {
          await api.auth.logout();
          self.setLoginStatus(LoginStatus.LoggedOut);
          window.location = window.location;
        } catch (error) {
          window.location = window.location;
        }
      },
      register: async (data: RegisterPayload) => {
        self.setRegisterStatus(false);
        try {
          const user = await self.registerSaveState.handlePromise(() => api.auth.register(data));
          if (user.email_verified_at) {
            // the user is validated.  We are authenticated
            self.setUser(user);

            await self.quiz.loadLatest();
            await self.truenorth.loadLatest();

            self.loginFetchState.resolve();
            self.setLoginStatus(LoginStatus.LoggedIn);
          }
          self.setRegisterStatus(true);
          self.registerSaveState.resolve();
        } catch (e) {
          self.setRegisterStatus(false);
          throw e;
        }
      },
      requestPasswordReset: async (data: RequestPasswordResetPayload) => {
        self.setRequestPasswordResetStatus(false);
        try {
          await api.auth.requestPasswordReset(data);
          self.setRequestPasswordResetStatus(true);
        } catch (error) {
          self.setRequestPasswordResetStatus(false);
        }
      },
      validateToken: async (data: ValidateTokenPayload) => {
        self.setLoginStatus(LoginStatus.LoggingIn);
        self.loginFetchState.pending();
        try {
          const user = await self.loginFetchState.handlePromise(() => api.auth.validateToken(data));
          self.setUser(user);
          // Takeover invitations if possible
          await self.takeoverInvitation();

          await self.quiz.loadLatest();
          await self.truenorth.loadLatest();

          self.loginFetchState.resolve();
          self.setLoginStatus(LoginStatus.LoggedIn);
        } catch (error) {
          self.loginFetchState.reject(error);
          self.setLoginStatus(LoginStatus.LoggedOut);
        }
      },
      resetPassword: async (data: ResetPasswordPayload) => {
        self.setLoginStatus(LoginStatus.LoggingIn);
        self.resetPasswordSaveState.pending();

        try {
          const response = await api.auth.resetPassword(data);
          self.setUser(response);
          // Takeover invitations if possible
          await self.takeoverInvitation();
          self.resetPasswordSaveState.resolve();
          self.setLoginStatus(LoginStatus.LoggedIn);
        } catch (error) {
          self.resetPasswordSaveState.reject(error);
          self.setLoginStatus(LoginStatus.LoggedOut);
          throw error;
        }
      },
      updateProfile: async (opportunistic = false) => {
        if (!self.editableUser || !self.user) {
          throw new Error("The user object doesn't exist.");
        }

        const oldUserSnapshot = getSnapshot(self.user);
        const snapshot = getSnapshot(self.editableUser);
        if (!snapshot) {
          throw new Error("The user object doesn't exist.");
        }

        // Update user objects with changes made on editable object
        if (opportunistic) {
          self.setUser(snapshot);
        }

        self.userSaveState.pending();

        try {
          const response = await api.auth.updateProfile(snapshot);
          self.setUser(response.data);
          self.userSaveState.resolve();
        } catch (e) {
          // Roll back changes if api call fails
          if (opportunistic) {
            self.setUser(oldUserSnapshot, { updateEditable: false });
          }

          self.userSaveState.reject(e);

          throw e;
        }

        return;
      },
    };
  });
