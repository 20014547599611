import { types, castToSnapshot, clone, getRoot, getSnapshot, getParent } from 'mobx-state-tree';

import api from '@/api';
import { Paginated } from '@/api/types';
import { Experience, ExperiencesListParams } from '@/api/endpoints/experiences';
import { InviteStatus, InviteType } from '@/api/endpoints/invites';
import { FileObject } from '@/api/endpoints/files';

import { RootStoreInstance } from '@/store';
import { withPaginated } from '@/store/models/paginated';
import FetchState from '@/store/models/fetch-state';
import ExperienceModel from '@/store/models/experience';
import UserRelationship from '@/store/models/user-relationship';
import { IFetchStatus } from '@/store/types/fetch-status';
import { TaxonomyRelationship } from '@/store/models/taxonomy';

export const ExperiencesStore = types
  .model('ExperiencesStore', {
    list: types.maybe(withPaginated(ExperienceModel).named('ExperiencesList')),
    listFetchState: types.optional(FetchState, {}),
    view: types.maybe(ExperienceModel),
    viewFetchState: types.optional(FetchState, {}),
    editable: types.maybe(ExperienceModel),
    editableFetchState: types.optional(FetchState, {}),
    editableSaveState: types.optional(FetchState, { status: IFetchStatus.resolved }),
    editableDeleteState: types.optional(FetchState, { status: IFetchStatus.resolved }),
  })
  .actions((self) => ({
    setList(list: Paginated<Experience[]>) {
      self.list = castToSnapshot(list);
    },

    setView(experience?: Experience) {
      if (!experience) {
        self.view = undefined;
      } else {
        self.view = castToSnapshot(experience);
      }
    },

    setEditable(experience?: Experience) {
      if (!experience) {
        self.editable = undefined;
      } else {
        self.editable = castToSnapshot(experience);
      }
    },
  }))
  .actions((self) => ({
    async loadList(params: ExperiencesListParams = {}) {
      const response = await self.listFetchState.handlePromise(() =>
        api.experiences.getList(params)
      );
      self.setList(response.data);
      self.listFetchState.resolve();
    },

    async loadListForUser(id: number) {
      const response = await self.listFetchState.handlePromise(() =>
        api.experiences.getAdminList(id)
      );
      self.setList(response.data);
      self.listFetchState.resolve();
    },

    async loadView(id: number) {
      const response = await self.viewFetchState.handlePromise(() => api.experiences.getItem(id));
      self.setView(response.data);
      self.viewFetchState.resolve();
    },

    async loadEditable(id?: number) {
      if (!id) {
        self.editableFetchState.pending();
        try {
          const root = getRoot(self) as { user: { id: number; email: string } };
          const model = ExperienceModel.create({
            id: 0,
            previous_id: 0,
            next_id: 0,
            status: 'active',
            title: '',
            body: '',
            rating: -1,
            shared: false,
            created_at: '',
            updated_at: '',
            deleted_at: undefined,
            type: [],
            reaction: undefined,
            images: [],
            comments: [],
            comments_count: 0,
            owner: UserRelationship.create({
              id: root.user.id,
              email: root.user.email,
              invite_id: 0,
              invite_status: InviteStatus.Pending,
              invite_type: InviteType.Student,
            }),
            guide: undefined,
            guide_notifications: false,
            student: undefined,
            student_notifications: false,
          });
          self.view = model;
          self.editable = clone(model);
          self.editableFetchState.resolve();
        } catch (e) {
          self.editableFetchState.reject(e);
        }

        return Promise.resolve();
      }

      const response = await self.editableFetchState.handlePromise(() =>
        api.experiences.getItem(id)
      );

      self.setView(response.data);
      self.setEditable(response.data);

      self.editableFetchState.resolve();
    },

    async saveEditable() {
      if (!self.editable) {
        throw new Error("The experience object doesn't exist.");
      }

      const snapshot = getSnapshot(self.editable);

      if (!snapshot) {
        throw new Error("The experience object doesn't exist.");
      }

      const response = await self.editableSaveState.handlePromise(() =>
        api.experiences.saveItem(snapshot)
      );

      self.setView(response.data);
      self.setEditable(response.data);

      self.editableSaveState.resolve();
    },

    async deleteEditable() {
      if (!self.editable || !self.editable.id) {
        throw new Error("The experience object doesn't exist.");
      }

      await self.editableDeleteState.handlePromise(() =>
        api.experiences.deleteItem(self.editable!.id)
      );

      self.setView(undefined);
      self.setEditable(undefined);

      self.editableDeleteState.resolve();
    },

    updateEditable(data: {
      title: string;
      body: string;
      shared_with?: string;
      type?: string;
      rating: string;
    }) {
      if (!self.editable) {
        throw new Error("The experience object doesn't exist.");
      }

      const [sharedWithType, sharedWithId] = (data.shared_with || '').split('_');

      const root = getParent<RootStoreInstance>(self);
      const guide =
        sharedWithType === 'guide'
          ? root.guides.list?.data.find((item) => item.guide.id === Number(sharedWithId))
          : undefined;
      const student =
        sharedWithType === 'student'
          ? root.students.list?.data.find((item) => item.student.id === Number(sharedWithId))
          : undefined;
      const type = data.type
        ? root.taxonomies.experienceTypes.list.find((item) => item.id === Number(data.type))
        : undefined;

      self.editable.title = data.title;
      self.editable.body = data.body;
      self.editable.rating = Number(data.rating);

      if (!guide) {
        self.editable.guide = undefined;
      } else {
        self.editable.guide = clone(guide.guide);
      }

      if (!student) {
        self.editable.student = undefined;
      } else {
        self.editable.student = clone(student.student);
      }

      if (!type) {
        self.editable.type.replace([]);
      } else {
        self.editable.type.replace([
          TaxonomyRelationship.create({
            id: type.id,
            title: type.title,
          }),
        ]);
      }
    },

    addImageToEditable(image: FileObject) {
      if (!self.editable) {
        throw new Error("The experience object doesn't exist.");
      }

      self.editable.images.push(image);
    },

    removeImageFromEditable(imageId: number) {
      if (!self.editable) {
        throw new Error("The experience object doesn't exist.");
      }

      const image = self.editable.images.find((image) => image.id === imageId);

      if (image) {
        self.editable.images.remove(image);
      }
    },
  }));

export default ExperiencesStore;
