import { types } from 'mobx-state-tree';

import { TaxonomyType } from '@/api/endpoints/taxonomies';

import { Taxonomy } from '@/store/models/taxonomy';
import { TaxonomyStoreFactory } from '@/store/stores/taxonomies/factory';

export const Major = Taxonomy.props({
  type: types.literal<TaxonomyType>(TaxonomyType.Major),
}).named('Major');

export default TaxonomyStoreFactory(Major, 'majors');
