import httpClient from '@/api/http-client';

interface QuizAnswer {
  id: number;
  question: string;
  answer: string;
}

export type QuizAttributeCategory = 'strengths' | 'skills' | 'values';

interface QuizAttributeObject {
  key: string;
  label: string;
  description?: string;
  value: number;
}

type QuizAttributes = {
  [key in QuizAttributeCategory]: QuizAttributeObject[] | never[];
};

export interface IQuiz {
  id: number;
  complete: boolean;
  answers: QuizAnswer[];
  calculated: QuizAttributes;
  attributes: QuizAttributes;
}

export async function getLatest() {
  return httpClient.get<IQuiz>('discovery/quiz');
}

export async function create(data: Omit<IQuiz, 'id'>) {
  return httpClient.post<IQuiz>('discovery/quiz', data);
}

export async function update(data: IQuiz) {
  if (!data.id) {
    throw new Error("The quiz object doesn't exist.");
  }

  return httpClient.post<IQuiz>(`discovery/quiz/${data.id}`, data);
}
