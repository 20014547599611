import httpClient from '@/api/http-client';
import { UserObject } from './me';
import { getInviteObject, setAccessToken, removeInviteObject } from './utils';
import { LoginResponse } from './login';

export interface RegisterPayload {
  first_name: string;
  last_name: string;
  email: string;
  research_consent: boolean;
  password: string;
  password_confirmation: string;
  profile: {
    major?: number[];
    minor?: number[];
    standing?: number;
    race?: number;
    gender?: number;
    purpose?: string[];
    college_student: boolean | undefined;
  };
  invite_token?: string;
  invite_id?: number;
}

export interface RegisterResponse extends UserObject {}

export async function register(data: RegisterPayload) {
  // Send the invite token along here so the user doesn't
  // have to validate their email again
  const inviteToken = getInviteObject();
  if (inviteToken) {
    data.invite_token = inviteToken.invite_token;
    data.invite_id = inviteToken.invite_id;
    // We expect to be authenticated, and a failure to authenticate while registering
    // indicates something wonky occured with our invitation
    const response = await httpClient.post<LoginResponse>('auth/register', data);

    removeInviteObject();

    if (!response.data.access_token) {
      setAccessToken(response.data.access_token);
    }

    return response.data.user;
  } else {
    // This will never authenticate the user because there is no invite to use
    const response = await httpClient.post<LoginResponse>('auth/register', data);
    return response.data.user;
  }
}
