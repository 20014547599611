import httpClient from '@/api/http-client';
import { Paginated, UserRelationship } from '@/api/types';
import { FileObject } from '@/api/endpoints/files';

interface Type {
  id: number;
  title: string;
}

export interface Reaction {
  emoji: string;
  time: string;
  user_id: number;
}

export type ReactionObject = Record<string, Record<string, Reaction>>;

export interface Comment {
  id: number;
  type: string;
  body: string;
  owner: UserRelationship;
  images: FileObject[];
  reaction?: ReactionObject;
  created_at?: string;
}

export interface Experience {
  id: number;
  previous_id: number;
  next_id: number;
  status: string;
  title: string;
  body: string;
  rating: number;
  shared: boolean;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
  type: Type[];
  reaction?: ReactionObject;
  images: FileObject[];
  comments: Comment[];
  comments_count: number;
  owner: UserRelationship;
  guide?: UserRelationship;
  guide_notifications: boolean;
  student?: UserRelationship;
  student_notifications: boolean;
}

export interface ExperiencesListParams {
  page?: number;
  type?: number;
  guide?: number;
  student?: number;
}

export async function getList({ page, type, guide, student }: ExperiencesListParams = {}) {
  return httpClient.get<Paginated<Experience[]>>('experiences', {
    params: { page, experience_type: type, guide_id: guide, student_id: student },
  });
}

export async function getAdminList(id: number) {
  return httpClient.get<Paginated<Experience[]>>(`user/${id}/experiences`);
}

export async function getItem(id: number) {
  return httpClient.get<Experience>(`experience/${id}`);
}

export async function saveItem({ id, ...data }: Experience) {
  if (id) {
    return httpClient.post<Experience>(`experience/${id}`, data);
  }

  return httpClient.post<Experience>('experience', data);
}

export async function deleteItem(id: number) {
  return httpClient.delete<Experience>(`experience/${id}`);
}

export async function postComment(
  experienceId: number,
  comment: Omit<Comment, 'id' | 'owner' | 'reaction'>
) {
  return httpClient.post<Comment>(`experience/${experienceId}/comment`, comment);
}

export async function likeItem(id: number) {
  return httpClient.post<Experience>(`experience/${id}/react`, {
    emoji: ':thumbsup:',
  });
}

export async function unlikeItem(id: number) {
  return httpClient.delete<Experience>(`experience/${id}/react`, {
    data: {
      emoji: ':thumbsup:',
    },
  });
}
