import { types, Instance } from 'mobx-state-tree';

import { TaxonomyType } from '@/api/endpoints/taxonomies';

export const Taxonomy = types.model('Taxonomy', {
  id: types.identifierNumber,
  type: types.literal<TaxonomyType>(TaxonomyType.ExperienceType),
  title: types.string,
  description: types.maybeNull(types.string),
  experiences_count: types.maybe(types.number),
  create_at: types.maybeNull(types.string),
  updated_at: types.maybeNull(types.string),
  deleted_at: types.maybeNull(types.string),
});

export type ITaxonomy = Instance<typeof Taxonomy>;

export const TaxonomyRelationship = types.model('TaxonomyRelationship', {
  id: types.identifierNumber,
  title: types.string,
});

export const convertTaxonomyToRelationship = <T extends ITaxonomy>(instance: T) => {
  return TaxonomyRelationship.create({
    id: instance.id,
    title: instance.title,
  });
};
