import httpClient from '@/api/http-client';

export interface TaxonomyJSON extends JSONObject {
  id: number;
  type: TaxonomyType;
  title: string;
  description?: string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  experiences_count?: number;
}

export type TaxonomyListResponse = TaxonomyJSON[];

export type TaxonomyAPIEndpoint =
  | 'majors'
  | 'minors'
  | 'genders'
  | 'races'
  | 'standings'
  | 'experience_types';

export enum TaxonomyType {
  Major = 'major',
  Minor = 'minor',
  Gender = 'gender',
  Race = 'race',
  Standing = 'standing',
  ExperienceType = 'experience_type',
}

export function list(endpoint: TaxonomyAPIEndpoint) {
  return httpClient.get<TaxonomyListResponse>(`taxonomies/${endpoint}`);
}
