import httpClient from '@/api/http-client';

export interface TrueNorthStatementEntry {
  id: number;
  question: string;
  type: 'yesno' | 'short-text' | 'text';
  answer: string | boolean;
  time: number;
  idle: number;
}

export interface TrueNorthStatementRecord {
  id: number;
  complete: boolean;
  total_time: number;
  idle_time: number;
  truenorth_statement?: string;
  entries: TrueNorthStatementEntry[];
}

export async function getLatest() {
  return httpClient.get<TrueNorthStatementRecord>('discovery/truenorth');
}

export async function create(data: Omit<TrueNorthStatementRecord, 'id'>) {
  return httpClient.post<TrueNorthStatementRecord>('discovery/truenorth', data);
}

export async function update({ id, ...data }: TrueNorthStatementRecord) {
  return httpClient.post<TrueNorthStatementRecord>(`discovery/truenorth/${id}`, data);
}

export async function save({
  id,
  ...data
}: Omit<TrueNorthStatementRecord, 'id'> & { id?: number }) {
  if (id) {
    return update({ id, ...data });
  }

  return create(data);
}
