import { types, IAnyType } from 'mobx-state-tree';

export const PaginatedModel = types.model('PaginatedModel', {
  path: types.string,
  from: types.maybe(types.number),
  to: types.maybe(types.number),
  total: types.number,
  per_page: types.number,
  current_page: types.number,
  last_page: types.number,
  first_page_url: types.string,
  last_page_url: types.string,
  next_page_url: types.maybe(types.string),
  prev_page_url: types.maybe(types.string),
});

export function withPaginated<M extends IAnyType>(model: M) {
  return PaginatedModel.named(`Paginated${model.name}`).props({
    data: types.array(model),
  });
}

export default PaginatedModel;
