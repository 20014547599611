import { types, castToSnapshot } from 'mobx-state-tree';

import api from '@/api';
import { Paginated } from '@/api/types';

import { withPaginated } from '@/store/models/paginated';
import FetchState from '@/store/models/fetch-state';
import { InviteObject, InviteType } from '@/api/endpoints/invites';
import InviteModel from '@/store/models/invite';

export const InvitesStoreFactory = <T extends InviteType>(type: T) => {
  return types
    .model('InvitesStore', {
      list: types.maybe(withPaginated(InviteModel).named('InvitationList')),
      listFetchState: types.optional(FetchState, {}),
      view: types.maybe(InviteModel),
      viewFetchState: types.optional(FetchState, {}),
    })
    .actions((self) => ({
      setList(response: Paginated<InviteObject[]>) {
        self.list = castToSnapshot(response);
      },
      setView(response?: InviteObject) {
        if (!response) {
          self.view = undefined;
        } else {
          self.view = castToSnapshot(response);
        }
      },
    }))
    .actions((self) => ({
      async loadList(page?: number) {
        const response = await self.listFetchState.handlePromise(() =>
          api.invites.getList(page, type)
        );
        self.setList(response.data);
        self.listFetchState.resolve();
      },
      async loadListForUser(user_id: number, page?: number) {
        const response = await self.listFetchState.handlePromise(() =>
          api.invites.getListForUser(user_id, page, type)
        );
        self.setList(response.data);
        self.listFetchState.resolve();
      },
      async loadView(id: number) {
        const response = await self.viewFetchState.handlePromise(() => api.invites.getInvite(id));
        self.setView(response.data);
        self.viewFetchState.resolve();
      },
    }));
};
