import { types, castToSnapshot, SnapshotOrInstance, getSnapshot } from 'mobx-state-tree';

import api from '@/api';
import { TrueNorthStatementRecord } from '@/api/endpoints/truenorth';

import { isAPIError } from '@/utils/errors';

import { FetchState } from '@/store/models/fetch-state';
import { IFetchStatus } from '@/store/types/fetch-status';

const TrueNorthStatementEntry = types.model('TrueNorthStatementEntry', {
  id: types.number,
  question: types.string,
  type: types.enumeration<'yesno' | 'short-text' | 'text'>(['yesno', 'short-text', 'text']),
  answer: types.union(types.string, types.boolean),
  time: types.number,
  idle: types.number,
});

export type ITrueNorthStatementEntry = SnapshotOrInstance<typeof TrueNorthStatementEntry>;

const TrueNorthStatement = types.model('TrueNorthStatement', {
  id: types.maybe(types.number),
  complete: types.optional(types.boolean, false),
  total_time: types.optional(types.number, 0),
  idle_time: types.optional(types.number, 0),
  truenorth_statement: types.maybe(types.string),
  entries: types.array(TrueNorthStatementEntry),
});

const TrueNorthStore = types
  .model({
    latest: types.maybe(TrueNorthStatement),
    latestFetchState: types.optional(FetchState, {}),
    editable: types.maybe(TrueNorthStatement),
    editableFetchState: types.optional(FetchState, {}),
    editableSaveState: types.optional(FetchState, { status: IFetchStatus.resolved }),
  })
  .views((self) => ({
    editableGetQuestionFirstEntry(questionId: number) {
      return self.editable?.entries.find((item) => item.id === questionId);
    },
    editableGetQuestionLastEntry(questionId: number) {
      const filtered = self.editable?.entries.filter((item) => item.id === questionId);
      if (!filtered || !filtered.length) {
        return undefined;
      }

      return filtered[filtered.length - 1];
    },
    editableGetEntryIndex(entry?: ITrueNorthStatementEntry): number {
      if (!entry || !self.editable) {
        return -1;
      }

      return self.editable.entries.findIndex((item) => item === entry);
    },
  }))
  .views((self) => ({
    editableHasQuestionEntry(questionId: number) {
      return Boolean(self.editableGetQuestionFirstEntry(questionId));
    },
  }))
  .actions((self) => ({
    setLatest(json: TrueNorthStatementRecord) {
      self.latest = castToSnapshot(json);
    },
    setEditable(json: TrueNorthStatementRecord) {
      self.editable = castToSnapshot(json);
    },
    completeEditable() {
      if (self.editable) {
        self.editable.complete = true;
      }
    },
    setTrueNorthStatement(statement: string) {
      if (self.editable) {
        self.editable.truenorth_statement = statement;
      }
    },
  }))
  .actions((self) => ({
    async loadLatest() {
      self.latestFetchState.pending();

      let response;
      try {
        response = await api.truenorth.getLatest();
      } catch (e) {
        // We don't want to throw an error here when we get a 404 response
        if (isAPIError(e)) {
          if (e.response?.status === 404) {
            self.latestFetchState.resolve();
            return;
          }
        }
        self.latestFetchState.reject(e);
        return;
      }

      if (response && response.data) {
        self.setLatest(response.data);
      }

      self.latestFetchState.resolve();
    },

    loadEditable() {
      self.editableFetchState.pending();
      try {
        self.editable = TrueNorthStatement.create({});
        self.editableFetchState.resolve();
      } catch (e) {
        self.editableFetchState.reject(e);
      }
    },

    async saveEditable() {
      if (!self.editable) {
        throw new Error("The guided journal object doesn't exist.");
      }

      const snapshot = getSnapshot(self.editable);

      if (!snapshot) {
        throw new Error("The guided journal object doesn't exist.");
      }

      const response = await self.editableSaveState.handlePromise(() =>
        api.truenorth.save(snapshot)
      );

      self.setEditable(response.data);
      self.setLatest(response.data);
      self.editableSaveState.resolve();
    },

    addEditableEntry(entry: ITrueNorthStatementEntry) {
      self.editable?.entries.push(entry);
    },
  }));

export default TrueNorthStore;
