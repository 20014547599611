import httpClient from '@/api/http-client';
import { LoginResponse } from './login';
import { setAccessToken, InviteTokenObject } from './utils';

export interface ValidateTokenPayload {
  token: string;
  email?: string;
}

export async function validateToken(data: ValidateTokenPayload) {
  const response = await httpClient.get<LoginResponse>('auth/token', {
    params: data,
    headers: {
      Authorization: undefined,
    },
  });

  if (!response.data.access_token) {
    throw new Error('Invalid auth/token response data.');
  }

  setAccessToken(response.data.access_token);

  return response.data.user;
}

export async function takeoverInvitation(data: InviteTokenObject) {
  const response = await httpClient.post<LoginResponse>('auth/takeover', data);
  return response.data.user;
}
