import { types, castToSnapshot, cast } from 'mobx-state-tree';

import api from '@/api';
import { Paginated } from '@/api/types';
import { withPaginated } from '@/store/models/paginated';
import FetchState from '@/store/models/fetch-state';
import { UserModel, User, IUserModel } from './user';
import { UserObject } from '@/api/endpoints/auth';
import { IFetchStatus } from '../types/fetch-status';
import { UsersListParams } from '@/api/endpoints/users';

export const UsersStore = types
  .model('UsersStore', {
    list: types.maybe(withPaginated(User).named('UsersList')),
    listFetchState: types.optional(FetchState, {}),
    view: types.maybe(User),
    viewFetchState: types.optional(FetchState, {}),
    editable: types.maybe(User),
    editableFetchState: types.optional(FetchState, {}),
    editableSaveState: types.optional(FetchState, { status: IFetchStatus.resolved }),
  })
  .actions((self) => ({
    setList(list: Paginated<UserObject[]>) {
      self.list = castToSnapshot(list);
    },

    setView(user: UserObject) {
      self.view = castToSnapshot(user);
    },

    setEditable(user: UserObject) {
      self.editable = castToSnapshot(user);
    },
  }))
  .actions((self) => ({
    async loadList(params: UsersListParams = {}) {
      const response = await self.listFetchState.handlePromise(() => api.users.getUsers(params));
      self.setList(response.data);
      self.listFetchState.resolve();
    },

    async loadView(id: number) {
      const response = await self.viewFetchState.handlePromise(() => api.users.getUser(id));
      self.setView(response.data);
      self.viewFetchState.resolve();
    },

    async deleteViewedUser() {
      if (!self.view || !self.view.id) {
        throw new Error("The user object doesn't exist.");
      }

      const response = await self.viewFetchState.handlePromise(() =>
        api.users.deleteUser(self.view!.id)
      );
      self.setView(response.data);
      self.viewFetchState.resolve();
    },
    async activateViewedUser() {
      if (!self.view || !self.view.id) {
        throw new Error("The user object doesn't exist.");
      }

      const response = await self.viewFetchState.handlePromise(() =>
        api.users.activate(self.view!.id)
      );
      self.setView(response.data);
      self.viewFetchState.resolve();
    },
    async promoteViewedUser() {
      if (!self.view || !self.view.id) {
        throw new Error("The user object doesn't exist.");
      }

      const response = await self.viewFetchState.handlePromise(() =>
        api.users.promote(self.view!.id)
      );
      self.setView(response.data);
      self.viewFetchState.resolve();
    },
    async demoteViewedUser() {
      if (!self.view || !self.view.id) {
        throw new Error("The user object doesn't exist.");
      }

      const response = await self.viewFetchState.handlePromise(() =>
        api.users.demote(self.view!.id)
      );
      self.setView(response.data);
      self.viewFetchState.resolve();
    },
  }));

export default UsersStore;
