import * as auth from './endpoints/auth';
import * as taxonomies from './endpoints/taxonomies';
import * as quiz from './endpoints/quiz';
import * as invites from './endpoints/invites';
import * as activities from './endpoints/activities';
import * as truenorth from './endpoints/truenorth';
import * as experiences from './endpoints/experiences';
import * as files from './endpoints/files';
import * as users from './endpoints/users';

export default {
  auth,
  taxonomies,
  quiz,
  invites,
  activities,
  truenorth,
  experiences,
  files,
  users,
};
