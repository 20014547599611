import httpClient from '@/api/http-client';
import { Paginated, UserRelationship } from '@/api/types';
import { AxiosResponse } from 'axios';

export enum InviteType {
  Student = 'student',
  Guide = 'guide',
}

export enum InviteStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Declined = 'declined',
  Canceled = 'canceled',
  Archived = 'archived',
}

export interface InviteGuideData {
  email: string;
}

export interface InviteStudentData {
  email: string;
}

export interface InviteObject<T extends InviteType = InviteType> {
  id: number;
  owner_id: number;
  type: T;
  status: string;
  created_at: string;
  updated_at: string;
  owner: UserRelationship;
  student: UserRelationship;
  guide: UserRelationship;
  experience_count: number;
}

export async function getList<T extends InviteType>(
  page?: number,
  type?: T
): Promise<AxiosResponse<Paginated<InviteObject<T>[]>>> {
  return httpClient.get('invites', {
    params: {
      type,
      page,
    },
  });
}

export async function getListForUser<T extends InviteType>(
  user_id: number,
  page?: number,
  type?: T
): Promise<AxiosResponse<Paginated<InviteObject<T>[]>>> {
  return httpClient.get('invites', {
    params: {
      type,
      user_id,
      page,
    },
  });
}

export async function getGuides(page?: number) {
  return httpClient.get<Paginated<InviteObject<InviteType.Guide>[]>>('invites', {
    params: {
      type: 'guide',
      page,
    },
  });
}

export async function getGuidesForUser(user_id: number, page?: number) {
  return httpClient.get<Paginated<InviteObject<InviteType.Guide>[]>>('invites', {
    params: {
      type: 'guide',
      user_id,
      page,
    },
  });
}

export async function getStudents(page?: number) {
  return httpClient.get<Paginated<InviteObject<InviteType.Student>[]>>('invites', {
    params: {
      type: 'student',
      page,
    },
  });
}

export async function getStudentsForUser(user_id: number, page?: number) {
  return httpClient.get<Paginated<InviteObject<InviteType.Guide>[]>>('invites', {
    params: {
      type: 'student',
      user_id,
      page,
    },
  });
}

export async function getInvite<T extends InviteType>(id: number) {
  return httpClient.get<InviteObject<T>>(`invites/${id}`);
}

export async function inviteGuide(data: InviteGuideData) {
  return httpClient.post('invite/guide', data);
}

export async function inviteStudent(data: InviteStudentData) {
  return httpClient.post('invite/student', data);
}

export async function accept(id: number) {
  return httpClient.post('invite/' + id + '/accept');
}

export async function decline(id: number) {
  return httpClient.post('invite/' + id + '/decline');
}

export async function cancel(id: number) {
  return httpClient.post('invite/' + id + '/cancel');
}

export async function archive(id: number) {
  return httpClient.post('invite/' + id + '/archive');
}
