import { types, Instance, getParent, castToSnapshot } from 'mobx-state-tree';

import { FileObject } from '@/api/endpoints/files';

import { File } from '@/store/models/file';
import { TaxonomyRelationship, convertTaxonomyToRelationship } from '@/store/models/taxonomy';
import { RootStoreInstance } from '@/store';

export const Profile = types
  .model('UserProfile', {
    major: types.array(TaxonomyRelationship),
    minor: types.array(TaxonomyRelationship),
    standing: types.maybe(TaxonomyRelationship),
    race: types.maybe(TaxonomyRelationship),
    gender: types.maybe(TaxonomyRelationship),
    purpose: types.array(types.string),
  })
  .views((self) => ({
    get primaryMajor() {
      if (!self.major.length) {
        return undefined;
      }

      return self.major[0];
    },
    get primaryMinor() {
      if (!self.minor.length) {
        return undefined;
      }

      return self.minor[0];
    },
  }));

export const UserModel = types
  .model('User', {
    id: types.identifierNumber,
    name: types.string,
    first_name: types.string,
    last_name: types.string,
    email: types.string,
    email_verified_at: types.maybe(types.string),
    password: types.maybe(types.string),
    password_confirmation: types.maybe(types.string),
    created_at: types.string,
    updated_at: types.string,
    last_login: types.maybe(types.string),
    login_count: types.optional(types.number, 0),
    avatar: types.maybe(types.string),
    photo: types.maybe(File),
    profile: types.optional(Profile, {}),
    research_consent: types.boolean,
    is_admin: types.optional(types.boolean, false),
    experiences_count: types.optional(types.number, 0),
    students_count: types.optional(types.number, 0),
    pending_students_count: types.optional(types.number, 0),
    guides_count: types.optional(types.number, 0),
    pending_guides_count: types.optional(types.number, 0),
    status: types.optional(types.number, 0),
  })
  .views((self) => ({
    get displayName() {
      if (self.name) {
        return self.name;
      }
      return self.email;
    },
    get displayAndEmail() {
      if (self.name) {
        return self.name + ` (${self.email})`;
      }
      return self.email;
    },
    get isAdmin() {
      return self.is_admin;
    },
    get registrationComplete() {
      return self.first_name !== '' && self.last_name !== '';
    },
  }));

export type IUserModel = Instance<typeof UserModel>;

export const User = UserModel.actions((self) => ({
  setAvatar(avatar: string) {
    self.avatar = avatar;
  },
  setPhoto(photo?: FileObject) {
    self.photo = castToSnapshot(photo);
  },
  applyEditProfileFields(data: {
    first_name: string;
    last_name: string;
    password: string | undefined;
    password_confirmation: string | undefined;
    email: string;
    research_consent: boolean;
    college_student?: boolean;
    profile: {
      standing?: string;
      major?: string[];
      minor?: string[];
      race?: string;
      gender?: string;
      purpose?: string[];
    };
  }) {
    const root = getParent<RootStoreInstance>(self);

    const standing = root.taxonomies.standings.list.find(
      (item) => item.id === Number(data.profile.standing)
    );
    const major = data.profile.major
      ? root.taxonomies.majors.list
          .filter((item) => data.profile.major!.indexOf(String(item.id)) > -1)
          .map((item) => {
            return convertTaxonomyToRelationship(item);
          })
      : [];
    const minor = data.profile.minor
      ? root.taxonomies.minors.list
          .filter((item) => data.profile.minor!.indexOf(String(item.id)) > -1)
          .map((item) => {
            return convertTaxonomyToRelationship(item);
          })
      : [];
    const race = root.taxonomies.races.list.find((item) => item.id === Number(data.profile.race));
    const gender = root.taxonomies.genders.list.find(
      (item) => item.id === Number(data.profile.gender)
    );

    self.first_name = data.first_name;
    self.last_name = data.last_name;
    self.password = data.password;
    self.password_confirmation = data.password_confirmation;
    self.email = data.email;
    self.research_consent = data.research_consent;
    self.profile.standing = standing ? convertTaxonomyToRelationship(standing) : undefined;
    self.profile.major.replace(major);
    self.profile.minor.replace(minor);
    self.profile.race = race ? convertTaxonomyToRelationship(race) : undefined;
    self.profile.gender = gender ? convertTaxonomyToRelationship(gender) : undefined;
    self.profile.purpose.replace(data.profile.purpose ? data.profile.purpose : []);
  },
}));
