import httpClient from '@/api/http-client';
import { me, UserObject } from './me';
import { setAccessToken, removeAccessToken, getAccessToken } from './utils';

export interface AuthData {
  access_token: string;
  expires_in: number;
}

export interface LoginResponse extends AuthData {
  user: UserObject;
}

export async function login(payload?: { email: string; password: string }) {
  if (!payload) {
    const token = getAccessToken();

    if (!token) {
      return;
    }

    setAccessToken(token);

    try {
      const response = await me();

      return response.data;
    } catch (e) {
      // Clean up token if it's invalid.
      removeAccessToken();

      throw e;
    }
  }

  const response = await httpClient.post<LoginResponse>(
    'auth/login',
    {
      email: payload.email,
      password: payload.password,
    },
    {
      headers: {
        Authorization: undefined,
      },
    }
  );

  if (!response.data.access_token) {
    throw new Error('Invalid auth/login response data.');
  }

  setAccessToken(response.data.access_token);

  return response.data.user;
}
