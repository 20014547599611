import { types } from 'mobx-state-tree';
import experienceTypes from './experience-types';
import genders from './genders';
import majors from './majors';
import minors from './minors';
import races from './races';
import standings from './standings';

export enum TaxonomyStore {
  Major = 'majors',
  Minor = 'minors',
  Gender = 'genders',
  Race = 'races',
  Standing = 'standings',
  ExperienceType = 'experienceTypes',
}

const TaxonomiesStore = types.model('TaxonomiesStore', {
  [TaxonomyStore.ExperienceType]: types.optional(experienceTypes, {}),
  [TaxonomyStore.Gender]: types.optional(genders, {}),
  [TaxonomyStore.Major]: types.optional(majors, {}),
  [TaxonomyStore.Minor]: types.optional(minors, {}),
  [TaxonomyStore.Race]: types.optional(races, {}),
  [TaxonomyStore.Standing]: types.optional(standings, {}),
});

export default TaxonomiesStore;
