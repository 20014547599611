import { types, Instance } from 'mobx-state-tree';

import { InviteStatus, InviteType } from '@/api/endpoints/invites';

import { File } from '@/store/models/file';
import { Profile } from '@/store/stores/user';
import { QuizAttributeMap } from './quiz';

export const UserRelationship = types
  .model('UserRelationship', {
    id: types.identifierNumber,
    email: types.string,
    name: types.maybe(types.string),
    avatar: types.maybe(types.string),
    photo: types.maybe(File),
    profile: types.maybe(Profile),
    invite_id: types.number,
    invite_status: types.enumeration<InviteStatus | ''>([
      '',
      InviteStatus.Pending,
      InviteStatus.Accepted,
      InviteStatus.Archived,
      InviteStatus.Canceled,
      InviteStatus.Declined,
    ]),
    invite_type: types.enumeration<InviteType | ''>(['', InviteType.Guide, InviteType.Student]),
    quiz: types.optional(QuizAttributeMap, {}),
    truenorth: types.maybe(types.string),
  })
  .views((self) => ({
    get displayName() {
      if (self.name) {
        return self.name;
      }

      return self.email;
    },
  }))
  .views((self) => ({
    get clientLink() {
      if (self.invite_type === '' || self.invite_id < 1) {
        return undefined;
      }

      const base = self.invite_type === InviteType.Student ? 'students' : 'guides';

      return `/${base}/${self.invite_id}`;
    },
  }));

export type IUserRelationship = Instance<typeof UserRelationship>;

export default UserRelationship;
