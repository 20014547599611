import { types, castToSnapshot, Instance } from 'mobx-state-tree';

import { isAPIErrorsObject, isAPIError, isAPIErrorMessage } from '@/utils/errors';

import { FetchStatus, IFetchStatus } from '@/store/types/fetch-status';

enum ErrorType {
  API_ERROR = 'api-error',
  APP_ERROR = 'app-error',
  NETWORK_ERROR = 'network-error',
}

export const FetchState = types
  .model('FetchState', {
    status: FetchStatus,
    error: types.maybe(types.string),
    fieldErrors: types.map(types.union(types.array(types.string), types.string)),
    errorType: types.maybe(
      types.enumeration<ErrorType>([
        ErrorType.API_ERROR,
        ErrorType.APP_ERROR,
        ErrorType.NETWORK_ERROR,
      ])
    ),
  })
  .views((self) => ({
    get hasError() {
      return typeof self.error === 'string' && self.error.length > 0;
    },
    get hasFieldErrors() {
      return self.fieldErrors.size > 0;
    },
    get isPending() {
      return self.status === IFetchStatus.pending;
    },
    get isResolved() {
      return self.status === IFetchStatus.resolved;
    },
    get isRejected() {
      return self.status === IFetchStatus.rejected;
    },
  }))
  .views((self) => {
    return {
      getFieldErrors<T extends string, U = { [K in T]?: string }>(
        fields: T[]
      ): U & { __other: string[] } {
        const errors = {} as U;
        const other: string[] = [];

        for (const [fieldKey, fieldError] of self.fieldErrors.entries()) {
          if (!fieldError) {
            continue;
          }

          if (fields.indexOf(fieldKey as T) !== -1) {
            // @ts-ignore
            errors[fieldKey] = String(fieldError);
          } else {
            other.push(String(fieldError));
          }
        }

        if (self.error) {
          other.push(self.error);
        }

        return {
          ...errors,
          __other: other,
        };
      },
    };
  })
  .actions((self) => {
    function setStatus(status: IFetchStatus.resolved | IFetchStatus.pending): void;
    function setStatus(status: IFetchStatus.rejected, error?: any): void;
    function setStatus(status: IFetchStatus, error?: any) {
      self.status = status;

      if (status === IFetchStatus.rejected) {
        if (isAPIError(error)) {
          if (isAPIErrorsObject(error)) {
            self.fieldErrors.replace(castToSnapshot(error.response.data.errors));
            self.errorType = ErrorType.API_ERROR;
          } else if (isAPIErrorMessage(error)) {
            self.error = error.response.data.message;
            self.errorType = ErrorType.API_ERROR;
          } else {
            self.error = 'Unable to connect to the server. Please try again later.';
            self.errorType = ErrorType.NETWORK_ERROR;
          }
        } else if (typeof error === 'string') {
          self.error = error;
          self.errorType = ErrorType.APP_ERROR;
        } else if (error instanceof Error) {
          self.error = error.message;
          self.errorType = ErrorType.APP_ERROR;
        } else {
          self.error =
            'There was a problem with processing your request. If this persists, please contact support.';
          self.errorType = ErrorType.APP_ERROR;
        }
      } else {
        self.error = undefined;
        self.errorType = undefined;
        self.fieldErrors.clear();
      }
    }

    return {
      setStatus,
    };
  })
  .actions((self) => ({
    pending() {
      self.setStatus(IFetchStatus.pending);
    },
    resolve() {
      self.setStatus(IFetchStatus.resolved);
    },
    reject(error?: any) {
      self.setStatus(IFetchStatus.rejected, error);
    },
  }))
  .actions((self) => ({
    async handlePromise<T>(promise: () => Promise<T>) {
      self.pending();

      try {
        const out = await promise();
        return out;
      } catch (e) {
        self.reject(e);
        throw e;
      }
    },
  }));

export type IFetchState = Instance<typeof FetchState>;

export default FetchState;
