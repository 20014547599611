import { types } from 'mobx-state-tree';

export const QuizAttributeModel = types.model('QuizAttribute', {
  key: types.string,
  label: types.string,
  description: types.maybe(types.string),
  value: types.number,
});

export const QuizAttributeMap = types.model('QuizAttributeMap', {
  strengths: types.array(QuizAttributeModel),
  skills: types.array(QuizAttributeModel),
  values: types.array(QuizAttributeModel),
});

export const QuestionAnswerModel = types.model('QuizAnswer', {
  id: types.identifier,
  answer: types.string,
  strengths: types.array(QuizAttributeModel),
  skills: types.array(QuizAttributeModel),
  values: types.array(QuizAttributeModel),
});

export const QuestionModel = types.model('QuizQuestion', {
  id: types.identifierNumber,
  question: types.string,
  answers: types.array(QuestionAnswerModel),
});

export const QuizModel = types.model('Quiz', {
  id: types.identifierNumber,
  complete: types.boolean,
  answers: types.array(
    types.model('QuizAnswer', {
      id: types.identifierNumber,
      question: types.string,
      answer: types.string,
    })
  ),
  calculated: QuizAttributeMap.named('QuizCalculated'),
  attributes: QuizAttributeMap.named('QuizAttributes'),
});
