import { types, castToSnapshot, Instance } from 'mobx-state-tree';

import api from '@/api';
import { Activity as ActivityJSON } from '@/api/endpoints/activities';

import { FetchState } from '@/store/models/fetch-state';
import { IFetchStatus } from '@/store/types/fetch-status';
import UserRelationship from '@/store/models/user-relationship';

export const Activity = types.model('Activity', {
  id: types.identifierNumber,
  type: types.optional(
    types.enumeration<'comment' | 'reaction' | 'experience' | 'invitation'>([
      'comment',
      'reaction',
      'experience',
      'invitation',
    ]),
    'comment'
  ),
  snippet: types.string,
  title: types.string,
  created_at: types.string,
  author: UserRelationship,
  asset: types.model({
    id: types.number,
    experience_id: types.maybe(types.number),
    type: types.string,
    body: types.string,
    owner: types.model({
      id: types.number,
      name: types.string,
    }),
  }),
});

export const ActivitiesStore = types
  .model('ActivitiesStore', {
    activities: types.array(Activity),
    activitiesFetchState: types.optional(FetchState, {}),
  })
  .views((self) => ({
    get isEmpty() {
      return self.activities.length === 0;
    },
  }))
  .actions((self) => ({
    setActivites(activities: ActivityJSON[]) {
      self.activities = castToSnapshot(activities);
    },
  }))
  .actions((self) => ({
    async loadActivities() {
      const response = await self.activitiesFetchState.handlePromise(() =>
        api.activities.getActivities()
      );
      self.setActivites(response.data.data);
      self.activitiesFetchState.resolve();
    },
  }));

export default ActivitiesStore;

export type IActivity = Instance<typeof Activity>;
