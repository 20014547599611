import { types, Instance, getRoot, castToSnapshot } from 'mobx-state-tree';

import api from '@/api';
import { ReactionObject, Comment } from '@/api/endpoints/experiences';

import UserRelationship from './user-relationship';
import { File } from './file';
import { TaxonomyRelationship } from './taxonomy';

export const experienceRatings: Record<number, string> = {
  0: 'Not Meaningful',
  1: 'Slightly Meaningful',
  2: 'Moderately Meaningful',
  3: 'Very Meaningful',
  4: 'Extremely Meaningful',
};

export const ReactionModel = types.model('Reaction', {
  emoji: types.string,
  time: types.string,
  user_id: types.number,
});

export const CommentModel = types.model('Comment', {
  id: types.identifierNumber,
  type: types.string,
  body: types.string,
  owner: UserRelationship,
  images: types.array(File),
  reaction: types.maybe(types.map(types.map(ReactionModel))),
  created_at: types.string,
});

export const ExperienceModel = types
  .model('Experience', {
    id: types.identifierNumber,
    previous_id: types.number,
    next_id: types.number,
    status: types.string,
    title: types.string,
    body: types.string,
    rating: types.number,
    shared: types.boolean,
    created_at: types.string,
    updated_at: types.string,
    deleted_at: types.maybe(types.string),
    type: types.array(TaxonomyRelationship),
    reaction: types.maybe(types.map(types.map(ReactionModel))),
    comments_count: types.optional(types.number, 0),
    images: types.array(File),
    comments: types.array(CommentModel),
    owner: UserRelationship,
    guide: types.maybe(UserRelationship),
    guide_notifications: types.boolean,
    student: types.maybe(UserRelationship),
    student_notifications: types.boolean,
  })
  .views((self) => ({
    get isNew() {
      return !self.id;
    },
    get sharedWith() {
      if (self.shared && self.guide && self.student) {
        const root = getRoot(self) as { user: { id: number } };
        const userId = root.user.id;

        if (userId === self.student.id) {
          return self.guide;
        }

        return self.student;
      }

      return undefined;
    },
    get primaryType() {
      if (self.type.length) {
        return self.type[0];
      }

      return undefined;
    },
    get likeReaction() {
      const reaction = self.reaction?.get(':thumbsup:');

      return reaction;
    },
  }))
  .views((self) => ({
    isLikedBy(userId: number) {
      if (!self.likeReaction || !self.likeReaction.has(String(userId))) {
        return false;
      }

      return true;
    },
  }))
  .views((self) => ({
    get isLikedByUser() {
      const root = getRoot(self) as { user: { id: number } };
      const userId = root.user.id;

      return self.isLikedBy(userId);
    },
    get isLikedBySharedWith() {
      const sharedWith = self.sharedWith;

      if (!sharedWith) {
        return false;
      }

      return self.isLikedBy(sharedWith.id);
    },
  }))
  .actions((self) => ({
    setReaction(reaction?: ReactionObject) {
      self.reaction = castToSnapshot(reaction);
    },
    addComment(comment: Comment) {
      self.comments.push(castToSnapshot(comment));
    },
  }))
  .actions((self) => ({
    async like() {
      const response = await api.experiences.likeItem(self.id);
      self.setReaction(response.data.reaction);
    },
    async unlike() {
      const response = await api.experiences.unlikeItem(self.id);
      self.setReaction(response.data.reaction);
    },
    async postComment(data: { body: string }) {
      const response = await api.experiences.postComment(self.id, {
        body: data.body,
        type: 'post',
        images: [],
      });
      self.addComment(response.data);
    },
  }))
  .actions((self) => ({
    toggleLike() {
      if (self.isLikedByUser) {
        return self.unlike();
      }

      return self.like();
    },
  }));

export default ExperienceModel;

export type IExperienceModel = Instance<typeof ExperienceModel>;
export type ICommentModel = Instance<typeof CommentModel>;
