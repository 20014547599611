import { types } from 'mobx-state-tree';

export enum IFetchStatus {
  pending = 'pending',
  resolved = 'resolved',
  rejected = 'rejected',
}

export const FetchStatus = types.optional(
  types.enumeration<IFetchStatus>([
    IFetchStatus.pending,
    IFetchStatus.resolved,
    IFetchStatus.rejected,
  ]),
  IFetchStatus.pending
);
