import httpClient from '@/api/http-client';
import { removeAccessToken } from './utils';

export interface LogoutResponse {
  message: string;
}

export async function logout(): Promise<void> {
  await httpClient.get<LogoutResponse>('auth/logout');

  removeAccessToken();
}
