import httpClient from '@/api/http-client';
import { UserObject } from '../auth';
import { Paginated, UserRelationship } from '@/api/types';

export interface UsersListParams {
  page?: number;
  sort?: string;
  direction?: string;
  name?: string;
  status?: number;
}

export async function getUsers({ page, sort, direction, name, status }: UsersListParams = {}) {
  if (sort === 'created_at') {
    direction = 'desc';
  }
  if (sort === 'updated_at') {
    direction = 'desc';
  }
  if (sort === 'last_login') {
    direction = 'desc';
  }
  if (sort === 'last_name') {
    direction = 'asc';
  }
  return httpClient.get<Paginated<UserObject[]>>('users', {
    params: {
      page,
      name,
      status,
      sort,
      direction,
    },
  });
}

export async function getUser(id?: number) {
  return httpClient.get<UserObject>(`user/${id}`);
}

export async function deleteUser(id?: number) {
  return httpClient.delete<UserObject>(`user/${id}`);
}

export async function activate(id?: number) {
  return httpClient.post<UserObject>(`user/${id}/activate`);
}
export async function promote(id?: number) {
  return httpClient.post<UserObject>(`user/${id}/promote`);
}
export async function demote(id?: number) {
  return httpClient.post<UserObject>(`user/${id}/demote`);
}
