import httpClient from '@/api/http-client';
import { LoginResponse } from './login';
import { setAccessToken } from './utils';

export interface RequestPasswordResetPayload {
  email: string;
}

export interface ResetPasswordPayload {
  password: string;
  password_confirmation: string;
  token: string;
  email: string;
}

export interface RequestPasswordResetResponse {
  success: string;
}

export function requestPasswordReset(data: RequestPasswordResetPayload) {
  return httpClient.post<RequestPasswordResetResponse>('auth/reset-password', data, {
    headers: {
      Authorization: undefined,
    },
  });
}

export async function resetPassword(data: ResetPasswordPayload) {
  // Verify the token
  const tokenResponse = await httpClient.get<LoginResponse>('auth/token', {
    params: data,
    headers: {
      Authorization: undefined,
    },
  });

  if (!tokenResponse.data.access_token) {
    throw new Error('Invalid auth/token response data.');
  }

  await httpClient.post(
    'auth/password',
    {
      password: data.password,
      password_confirmation: data.password_confirmation,
    },
    {
      headers: {
        Authorization: `Bearer ${tokenResponse.data.access_token}`,
      },
    }
  );

  setAccessToken(tokenResponse.data.access_token);

  return tokenResponse.data.user;
}
