import { types } from 'mobx-state-tree';

import { TaxonomyType } from '@/api/endpoints/taxonomies';

import { Taxonomy } from '@/store/models/taxonomy';
import { TaxonomyStoreFactory } from '@/store/stores/taxonomies/factory';

export const Minor = Taxonomy.props({
  type: types.literal<TaxonomyType>(TaxonomyType.Minor),
}).named('Minor');

export default TaxonomyStoreFactory(Minor, 'minors');
