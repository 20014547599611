import { types } from 'mobx-state-tree';

import { TaxonomyType } from '@/api/endpoints/taxonomies';

import { Taxonomy } from '@/store/models/taxonomy';
import { TaxonomyStoreFactory } from '@/store/stores/taxonomies/factory';

export const ExperienceType = Taxonomy.props({
  type: types.literal<TaxonomyType>(TaxonomyType.ExperienceType),
}).named('ExperienceType');

export default TaxonomyStoreFactory(ExperienceType, 'experience_types');
