import { types } from 'mobx-state-tree';

import { TaxonomyType } from '@/api/endpoints/taxonomies';

import { Taxonomy } from '@/store/models/taxonomy';
import { TaxonomyStoreFactory } from '@/store/stores/taxonomies/factory';

export const Gender = Taxonomy.props({
  type: types.literal<TaxonomyType>(TaxonomyType.Gender),
}).named('Gender');

export default TaxonomyStoreFactory(Gender, 'genders');
