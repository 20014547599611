import { createContext, useContext } from 'react';
import { Instance } from 'mobx-state-tree';
import makeInspectable from 'mobx-devtools-mst';
import { RootStore } from './stores/root';

export const store = RootStore.create({});

makeInspectable(store);

export type RootStoreInstance = Instance<typeof RootStore>;
const RootStoreContext = createContext<null | RootStoreInstance>(null);
RootStoreContext.displayName = 'RootStoreContext';

export const Provider = RootStoreContext.Provider;
export function useStore() {
  const store = useContext<null | RootStoreInstance>(RootStoreContext);

  if (store === null) {
    throw new Error("Couldn't initialize an instance of application data store.");
  }

  return store;
}
