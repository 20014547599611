import { types } from 'mobx-state-tree';

import { TaxonomyType } from '@/api/endpoints/taxonomies';

import { Taxonomy } from '@/store/models/taxonomy';
import { TaxonomyStoreFactory } from '@/store/stores/taxonomies/factory';

export const Standing = Taxonomy.props({
  type: types.literal<TaxonomyType>(TaxonomyType.Standing),
}).named('Standing');

export default TaxonomyStoreFactory(Standing, 'standings');
