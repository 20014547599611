import {
  types,
  Instance,
  getParentOfType,
  getSnapshot,
  castToSnapshot,
  SnapshotIn,
  clone,
} from 'mobx-state-tree';

import api from '@/api';
import { IQuiz, QuizAttributeCategory } from '@/api/endpoints/quiz';

import { isAPIError } from '@/utils/errors';

import { IFetchStatus } from '@/store/types/fetch-status';
import { FetchState } from '@/store/models/fetch-state';
import { RootStore } from '@/store/stores/root';
import {
  QuestionModel,
  QuestionAnswerModel,
  QuizModel,
  QuizAttributeModel,
  QuizAttributeMap,
} from '@/store/models/quiz';
export const quizAttributeCategories: QuizAttributeCategory[] = ['values', 'strengths', 'skills'];

const sanitizeAttributeValue = (value: number) => Math.floor(value * 100) / 100;

export enum QuizStage {
  Welcome = 'welcome',
  Quiz = 'quiz',
  ChooseAvatar = 'choose-avatar',
  ResultsReflection = 'results-reflection',
  InviteGuide = 'invite-guide',
}

const AnswerModel = types.model('QuizAnswer', {
  id: types.identifierNumber,
  question: types.reference(QuestionModel),
  answer: types.reference(QuestionAnswerModel),
});

export type IAnswerModel = Instance<typeof AnswerModel>;

const QuizStore = types
  .model('QuizStore', {
    questions: types.array(QuestionModel),
    questionsFetchState: types.optional(FetchState, {}),
    latest: types.maybe(QuizModel),
    latestFetchState: types.optional(FetchState, {}),
    editable: types.maybe(QuizModel),
    editableFetchState: types.optional(FetchState, {}),
    editableSaveState: types.optional(FetchState, { status: IFetchStatus.resolved }),
    editableStage: types.optional(
      types.enumeration<QuizStage>([
        QuizStage.Welcome,
        QuizStage.Quiz,
        QuizStage.ChooseAvatar,
        QuizStage.ResultsReflection,
        QuizStage.InviteGuide,
      ]),
      QuizStage.Welcome
    ),
    editableAnswers: types.array(AnswerModel),
    editableCurrentQuestion: types.maybe(types.reference(QuestionModel)),
  })
  .views((self) => ({
    get currentQuestionIndex() {
      if (!self.editableCurrentQuestion) {
        return -1;
      }

      return self.questions.findIndex((item) => item.id === self.editableCurrentQuestion!.id);
    },
    get numberOfQuestions() {
      return self.questions.length;
    },
    get currentQuestionAnswer() {
      if (!self.editableCurrentQuestion) {
        return undefined;
      }

      return self.editableAnswers.find(
        (item) => item.question.id === self.editableCurrentQuestion!.id
      );
    },
  }))
  .views((self) => ({
    get hasPreviousQuestion() {
      return self.currentQuestionIndex > 0;
    },
  }))
  .actions((self) => ({
    setQuestions(questions: SnapshotIn<typeof QuestionModel>[]) {
      self.questions.replace(castToSnapshot(questions));
    },
    setCurrentQuestion(question: IQuizQuestion | undefined) {
      self.editableCurrentQuestion = question;
    },
    setStage(stage: QuizStage) {
      self.editableStage = stage;
    },
    updateAnswersFromQuiz() {
      // Update only from saved quiz
      if (!self.editable) {
        return;
      }

      const answers = self.editable.answers
        .map((item) => {
          const question = self.questions.find((q) => q.id === item.id);

          if (!question) {
            return undefined;
          }

          const answer = question.answers.find((a) => a.answer === item.answer);

          if (!answer) {
            return undefined;
          }

          return {
            id: item.id,
            question,
            answer,
          };
        })
        .filter((item): item is IAnswerModel => typeof item !== 'undefined');
      self.editableAnswers.replace(answers);
    },
    setLatest(quiz?: IQuiz) {
      if (!quiz) {
        self.latest = undefined;
      } else {
        self.latest = castToSnapshot(quiz);
      }
    },
    setEditable(quiz?: IQuiz) {
      if (!quiz) {
        self.editable = undefined;
      } else {
        self.editable = castToSnapshot(quiz);
      }
    },
    setEditableQuizComplete(complete: boolean) {
      if (self.editable) {
        self.editable.complete = complete;
      }
    },
  }))
  .actions((self) => ({
    async loadQuestions() {
      self.questionsFetchState.pending();
      try {
        const { default: data } = await import('@/json/quiz-questions.json');

        self.setQuestions((data as unknown) as SnapshotIn<typeof QuestionModel>[]);
        self.setCurrentQuestion(self.questions[0]);
        self.updateAnswersFromQuiz();
        self.questionsFetchState.resolve();
      } catch (e) {
        self.questionsFetchState.reject(e);
      }
    },
    async loadLatest() {
      self.latestFetchState.pending();

      let response;
      try {
        response = await api.quiz.getLatest();
      } catch (e) {
        // We don't want to throw an error here when we get a 404 response
        if (isAPIError(e)) {
          if (e.response?.status === 404) {
            self.latestFetchState.resolve();
            return;
          }
        }
        self.latestFetchState.reject(e);
        return;
      }

      if (response && response.data) {
        self.setLatest(response.data);
      }

      self.latestFetchState.resolve();
    },
    loadEditable() {
      // Reset answers and current question record
      self.editableAnswers.clear();
      self.editableCurrentQuestion = undefined;

      if (self.latest) {
        if (!self.latest.complete) {
          // If latest quiz is not completed then allow user to finish it
          self.setStage(QuizStage.Quiz);
          self.editable = clone(self.latest);
        } else {
          // Copy answers, calculated attributes and selected attributes from
          // the latest quiz and redirect user to results reflection stage
          self.setStage(QuizStage.ResultsReflection);
          self.editable = QuizModel.create({
            id: 0,
            complete: false,
            answers: clone(self.latest.answers),
            calculated: clone(self.latest.calculated),
            attributes: {
              strengths: [],
              skills: [],
              values: [],
            },
          });
          self.updateAnswersFromQuiz();
        }
      } else {
        // Start from scratch
        self.setStage(QuizStage.Welcome);

        self.editable = QuizModel.create({
          id: 0,
          complete: false,
          answers: [],
          calculated: {
            strengths: [],
            skills: [],
            values: [],
          },
          attributes: {
            strengths: [],
            skills: [],
            values: [],
          },
        });
      }
    },
    updateQuizCalculatedData() {
      if (!self.editable) {
        return;
      }

      self.editable.answers.replace(
        self.editableAnswers.map((item) => ({
          id: item.id,
          question: item.question.question,
          answer: item.answer.answer,
        }))
      );

      const calculated = quizAttributeCategories.reduce((res, category) => {
        res[category] = self.editableAnswers
          // join all attributes from all answers into one array
          .flatMap((answer) => answer.answer[category])
          // concatenate same key attributes and sum their values
          .reduce((out, item) => {
            // find attribute with key
            const index = out.findIndex((i) => i.key === item.key);
            if (index !== -1) {
              // sum values if attribute with key already exist
              out[index].value = sanitizeAttributeValue(out[index].value + item.value);
            } else {
              // push new attribute
              out.push({ ...item, value: sanitizeAttributeValue(item.value * 100) });
            }

            return out;
          }, [] as SnapshotIn<typeof QuizAttributeModel>[])
          .sort((a, b) => b.value - a.value);

        return res;
      }, {} as SnapshotIn<typeof QuizAttributeMap>);
      self.editable.calculated = castToSnapshot(calculated);
    },
    async saveEditable() {
      if (!self.editable) {
        throw new Error("The quiz object doesn't exist.");
      }

      const data = getSnapshot(self.editable);
      if (!data) {
        throw new Error("The quiz object doesn't exist.");
      }

      const isCompleted = data.complete;

      let response;

      try {
        if (!data.id) {
          const { id, ...createData } = data;
          response = await self.editableSaveState.handlePromise(() => api.quiz.create(createData));
        } else {
          response = await self.editableSaveState.handlePromise(() => api.quiz.update(data));
        }

        if (isCompleted) {
          self.setLatest(response.data);
          self.setEditable(undefined);
        } else {
          self.setEditable(response.data);
        }

        self.editableSaveState.resolve();
      } catch (e) {
        self.editableSaveState.reject(e);
      }
    },
  }))
  .actions((self) => ({
    setCurrentQuestionAnswer(answer: IQuizQuestionAnswer) {
      const root = getParentOfType(self, RootStore);

      if (!self.editableCurrentQuestion) {
        return;
      }

      // Get current question index. This is used to determine the next question or next stage.
      const questionIndex = self.questions.findIndex(
        (item) => item.id === self.editableCurrentQuestion!.id
      );

      if (questionIndex === -1) {
        return;
      }

      const question = self.questions[questionIndex];
      let answerItem = self.editableAnswers.find((item) => item.question.id === question.id);

      // Check if we are updating existing answer or adding a new one.
      if (!answerItem) {
        self.editableAnswers.push({
          id: self.editableCurrentQuestion.id,
          question: self.editableCurrentQuestion,
          answer,
        });
      } else {
        answerItem.answer = answer;
      }

      // If we are on the last question then go to next stage.
      if (self.questions.length === questionIndex + 1) {
        self.updateQuizCalculatedData();
        self.saveEditable();
        if (!root.user?.avatar && !root.user?.photo) {
          self.setStage(QuizStage.ChooseAvatar);
        } else {
          self.setStage(QuizStage.ResultsReflection);
        }
      } else {
        // Show the next question.
        self.editableCurrentQuestion = self.questions[questionIndex + 1];
      }
    },
    setAvatar(avatar: string) {
      const root = getParentOfType(self, RootStore);

      if (!root.editableUser) {
        throw new Error("The user object doesn't exist.");
      }

      root.editableUser.setAvatar(avatar);
      root.updateProfile(true).catch(() => {});
      self.setStage(QuizStage.ResultsReflection);
    },
    goToPreviousQuestion() {
      if (!self.editableCurrentQuestion) {
        self.setCurrentQuestion(self.questions[0]);
        return;
      }

      // Get current question index. This is used to determine the next question or next stage.
      const questionIndex = self.questions.findIndex(
        (item) => item.id === self.editableCurrentQuestion!.id
      );

      if (questionIndex < 2) {
        self.setCurrentQuestion(self.questions[0]);
        return;
      }

      self.setCurrentQuestion(self.questions[questionIndex - 1]);
    },
    setResultAttribute(
      category: QuizAttributeCategory,
      item: IQuizAttributeModel,
      selected: boolean
    ) {
      if (!self.editable) {
        return;
      }

      const attribute = self.editable.attributes[category].find((a) => a.key === item.key);

      if (selected) {
        if (attribute) {
          return;
        }

        if (self.editable.attributes[category].length >= 3) {
          return;
        }

        self.editable.attributes[category].push(clone(item));
      } else {
        if (!attribute) {
          return;
        }

        self.editable.attributes[category].remove(attribute);
      }
    },
  }));

export default QuizStore;

export type IQuizStore = Instance<typeof QuizStore>;
export type IQuizModel = Instance<typeof QuizModel>;
export type IQuizAttributeModel = Instance<typeof QuizAttributeModel>;
export type IQuizQuestion = Instance<typeof QuestionModel>;
export type IQuizQuestionAnswer = Instance<typeof QuestionAnswerModel>;
